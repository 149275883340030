import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, generatePath, useNavigate, useParams } from 'react-router-dom';

import ActionDialog from '~components/ActionDialog';
import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import InfoCard from '~components/InfoCard';
import OberonCard from '~components/OberonCard';
import useLeadListImportData from '~hooks/useLeadListImportData';
import { LeadListImportData } from '~hooks/useLeadListImportData/domain';
import { ListImport } from '~pages/CampaignManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { applyImportList, discardImportList, getListImportById } from '../../../api';

enum ProcessType {
  None = 0,
  Apply,
  Discard,
}

type Props = {
  filteredImportItems: LeadListImportData[];
  intersectionObserverRef: (node: any) => void;
};

const ErrorCards = ({ filteredImportItems, intersectionObserverRef }: Props) => (
  <>
    {filteredImportItems.map((item, index) => {
      const id = `${item.importId}-${item.importRecordNumber}`;
      // Should always exist if we fall into here
      const content = item.originalData as string;
      let error = '';

      if (item.parseError) {
        error = item.parseError;
      } else if (item.importError) {
        error = item.importError;
      } else {
        error = item.importWarning as string;
      }

      return (
        <OberonCard
          ref={index === filteredImportItems.length - 1 ? intersectionObserverRef : undefined}
          key={id}
          reverseTitles
          titleFontWeight={700}
          title={error}
          subHeader={`Row ${item.importRecordNumber}`}
          footer={
            content && (
              <Typography variant='body1' color='textPrimary'>
                {content}
              </Typography>
            )
          }
        />
      );
    })}
  </>
);

const ImportDetails = () => {
  const { pushNotification } = useNotification();
  const { campaignId, listId, importId } = useParams() as {
    campaignId: string;
    listId: string;
    importId: string;
  };
  const navigate = useNavigate();

  const [importDetail, setImportDetail] = useState<ListImport | undefined>();
  const [shouldFetch, setShouldFetch] = useState(false);
  const {
    loading,
    error: importFetchError,
    importData,
    hasMore,
    intersectionObserverRef,
  } = useLeadListImportData(+campaignId, +listId, +importId, { shouldFetch });
  const [importError, setImportError] = useState<{ text: string; subText: string } | undefined>();

  const isImportDetailDirty = Boolean(importDetail?.applied ?? importDetail?.discarded);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isProcessing = isSubmitting || Boolean(importDetail?.processTimestamp && !isImportDetailDirty);
  const [processType, setProcessType] = useState<ProcessType>(ProcessType.None);

  const errorResult = useMemo(
    () => importData?.filter((i) => i.importError || i.importWarning || i.parseError),
    [importData],
  );
  const displayErrorResult = useMemo(
    () =>
      Boolean(errorResult?.length) && (
        <Grid style={{ marginTop: 16 }} item xs={12}>
          <Typography variant='h4' component='h1' gutterBottom>
            Errors
          </Typography>

          <Typography variant='body1' component='p' paragraph>
            The following errors have been found with the uploaded file.
          </Typography>

          <ErrorCards filteredImportItems={errorResult!} intersectionObserverRef={intersectionObserverRef} />
        </Grid>
      ),
    [errorResult],
  );
  const errorAction = () => {
    navigate(
      generatePath(Routes.viewCampaignLeadList.path + '?show=UploadLeads', {
        campaignId,
        listId,
      }),
    );
  };
  const checkImportProcess = async (importId: number) => {
    while (true) {
      const listImport = await getListImportById(+campaignId, +listId, importId);
      setImportDetail(listImport);

      if (!listImport.processTimestamp || Boolean(listImport.applied ?? listImport.discarded)) {
        break;
      }
      // wait for 2seconds
      await new Promise((r) => setTimeout(r, 2000));
    }
  };
  const applyListUpload = async () => {
    if (!importDetail) {
      console.error('! applyListUpload: uploadResults does not exist.');
      return;
    }

    try {
      setIsSubmitting(true);
      await applyImportList(+campaignId, +listId, importDetail.importId);
      await checkImportProcess(importDetail.importId);

      pushNotification('success', 'Successfully completed list upload.');
    } catch (e) {
      pushNotification('error', 'Unable to complete list upload.');
    } finally {
      setIsSubmitting(false);
      setProcessType(ProcessType.None);
    }
  };
  const cancelListUpload = async () => {
    if (!importDetail) {
      console.error('! cancelListUpload: uploadResults does not exist.');
      return;
    }

    try {
      setIsSubmitting(true);
      await discardImportList(+campaignId, +listId, importDetail.importId);
      await checkImportProcess(importDetail.importId);
      pushNotification('success', 'Successfully discarded list upload.');
    } catch (e) {
      pushNotification('error', 'Unable to discard list upload.');
    } finally {
      setIsSubmitting(false);
      setProcessType(ProcessType.None);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await checkImportProcess(+importId);
      } catch (e) {
        if (e instanceof APIError) {
          setImportError({ text: 'Unable to request data from backend', subText: e.message });
        }

        if (e instanceof UnsupportedStructureError) {
          setImportError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (importDetail) {
      setShouldFetch(true);
      if (importDetail.processTimestamp) {
        setProcessType(ProcessType.Apply);
      }
    }
  }, [importDetail]);

  return (
    <AsyncLoader
      isLoading={!importDetail}
      error={importError && <EmptyState type='error' text={importError.text} subText={importError.subText} />}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.diallerConfig.path}>
          Campaigns
        </Link>

        <Link
          underline='hover'
          color='inherit'
          component={RouterLink}
          to={generatePath(Routes.viewCampaign.path, { campaignId })}>
          Campaign Details
        </Link>

        <Link
          underline='hover'
          color='inherit'
          component={RouterLink}
          to={generatePath(Routes.viewCampaignLeadList.path + '?show=UploadLeads', {
            campaignId,
            listId,
          })}>
          List Details
        </Link>

        <Typography color='textPrimary'>Imports Details</Typography>
      </Breadcrumbs>

      {!importDetail && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to list details.'
          action={errorAction}
          actionText='List Details'
        />
      )}

      {importDetail && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {`${importDetail.fileName} #${importDetail.importId}`}
          </Typography>
          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
            <LoadingButton
              sx={{ marginBottom: 1 }}
              variant='contained'
              fullWidth
              disableElevation
              color='success'
              loading={processType == ProcessType.Apply && isProcessing}
              disabled={processType != ProcessType.None || isImportDetailDirty || isProcessing}
              onClick={() => setProcessType(ProcessType.Apply)}>
              Process Import
            </LoadingButton>

            <LoadingButton
              variant='contained'
              fullWidth
              disableElevation
              color='error'
              loading={processType == ProcessType.Discard && isProcessing}
              disabled={processType != ProcessType.None || isImportDetailDirty || isProcessing}
              onClick={() => setProcessType(ProcessType.Discard)}>
              Discard Import
            </LoadingButton>
          </Box>

          <ActionDialog
            primaryActionTitle='Process Import'
            title='Are you sure you want to do this?'
            content={`You're about to apply the import list file '${importDetail.fileName}', once you have completed this action it cannot be undone.`}
            open={processType == ProcessType.Apply && !importDetail.processTimestamp}
            onAccept={() => {
              applyListUpload();
            }}
            onClose={() => {
              setProcessType(ProcessType.None);
            }}
            loading={isSubmitting}
          />

          <ActionDialog
            primaryActionTitle='Discard Import'
            title='Are you sure you want to do this?'
            content={`You're about to discard the import list file '${importDetail.fileName}', once you have completed this action it cannot be undone.`}
            open={processType == ProcessType.Discard && !importDetail.processTimestamp}
            onAccept={() => {
              cancelListUpload();
            }}
            onClose={() => {
              setProcessType(ProcessType.None);
            }}
            loading={isSubmitting}
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <InfoCard variant='success' icon={CheckCircleIcon} text={importDetail.validCount} subText='Valid Rows' />
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoCard
                variant='warning'
                icon={ErrorIcon}
                text={importDetail.importWarningCount}
                subText='Rows with Import Warnings'
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoCard
                variant='danger'
                icon={CancelIcon}
                text={importDetail.importErrorCount}
                subText='Rows with Import Errors'
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoCard
                variant='danger'
                icon={CancelIcon}
                text={importDetail.parseErrorCount}
                subText='Rows with Parsing Errors'
              />
            </Grid>
          </Grid>

          {displayErrorResult}
          {displayErrorResult && loading && hasMore && <DotLoader align='center' />}
          {displayErrorResult && !loading && !hasMore && (
            <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
              No more results to display
            </Typography>
          )}
          {!displayErrorResult && importFetchError && (
            <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant='body2' align='center' color='textSecondary'>
              Failed to load import data
            </Typography>
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default ImportDetails;
